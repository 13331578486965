/**
 * Created by piotr on 08.11.2017.
 */
jQuery(document).ready(function(){
    if (!$('#contact-us-form').length) return;

    jQuery('#contact-us-form').parsley();
    jQuery('#contact-us-form').submit(function (e) {

        if(grecaptcha.getResponse().length > 0){

            var url = "sendmail.php"; // the script where you handle the form input.

            jQuery.ajax({
                type: "POST",
                url: url,
                dataType: 'JSON',
                data: jQuery('#contact-us-form').serialize(), // serializes the form's elements.
                success: function (result, status, xhr) {
                    var ok = $('.parsley-error').length === 0;
                    jQuery('.ui-widget.notice,.ui-widget.error').hide();
                    console.log(result);
                    console.log(result.message);
                    if (result.message == 'ok') {
                        jQuery('.ui-widget.notice').show();
                        jQuery('.bs-callout-info').toggleClass('hidden', !ok);
                    } else {
                        var errorMessage = '';
                        console.log(result);
                        console.log(typeof result);
                        if(typeof result == "object") {
                            if (result.error.toString().indexOf("PHPMailer") !== -1) {
                                errorMessage = 'PHPMailer problem, please contact jan@codingmice.com directly';
                            } else {
                                errorMessage = 'There were problems submitting your form, please recheck your form or contact jan@codingmice.com directly.';
                            }
                        } else {
                            errorMessage = 'Server response problem, please contact jan@codingmice.com directly';
                        }
                        jQuery('.ui-widget.error .mui--text-accent').html(errorMessage);
                        jQuery('.ui-widget.error').show();
                        jQuery('.bs-callout-warning').toggleClass('hidden', ok);
                    }
                    var statusMessage = '';
                    console.log(xhr.status);
                    if(xhr.status !== 200){
                        statusMessage = 'Server response status = ' + xhr.status +  ', please contact jan@codingmice.com directly';
                        jQuery('.ui-widget.error .mui--text-accent').html(statusMessage);
                        jQuery('.ui-widget.error').show();
                        jQuery('.bs-callout-info').toggleClass('hidden', ok);
                        jQuery('.ui-widget.notice').hide();
                    }
                },
                error: function(xhr) {
                    var errorMessage1 = 'An error occurred while processing the request, please contact jan@codingmice.com directly';
                    jQuery('.ui-widget.error .mui--text-accent').html(errorMessage1);
                    jQuery('.ui-widget.error').show();
                }
            });
        } else {
            var errorMessage = 'Please make sure you solve reCAPTCHA first';
            jQuery('.ui-widget.error .mui--text-accent').html(errorMessage);
            jQuery('.ui-widget.error').show();
            return false;
        }
        /* avoid to execute the actual submit of the form.*/
        e.preventDefault();

    });

    /*Like described in https://dev.to/uf4no/improve-page-performance-lazy-loading-recaptcha-442o */

    // Function that loads recaptcha on form input focus
    function reCaptchaOnFocus() {
        //alert("Focus works");
        var body = document.getElementsByTagName('body')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.google.com/recaptcha/api.js'
        body.appendChild(script);

        // remove focus to avoid js error:
        document.getElementById('inputName').removeEventListener('focus', reCaptchaOnFocus);
        document.getElementById('inputEmail').removeEventListener('focus', reCaptchaOnFocus);
        document.getElementById('work_type').removeEventListener('focus', reCaptchaOnFocus);
        document.getElementById('planned-launch').removeEventListener('focus', reCaptchaOnFocus);
    }
// add initial event listener to the form inputs
    document.getElementById('inputName').addEventListener('focus', reCaptchaOnFocus, false);
    document.getElementById('inputEmail').addEventListener('focus', reCaptchaOnFocus, false);
    document.getElementById('work_type').addEventListener('focus', reCaptchaOnFocus, false);
    document.getElementById('planned-launch').addEventListener('focus', reCaptchaOnFocus, false);

// use only when jquery UI is used:
    // jQuery("#planned-launch").datepicker();


    $('select').css('opacity','0.5');
    $('select').change(function() {
        var current = $('select').val();
        if (current != 'null') {
            $('select').css('opacity','1');
        } else {
            $('select').css('opacity','0.5');
        }
    });

    var chooseWorkType = $('.choose-work-type');

    $('#submit-contact-us').click(function(){
        if ($("#work_type").find("option:selected").text() == 'Choose Work Type') {
            chooseWorkType.addClass('has-error');
        } else {
            chooseWorkType.removeClass('has-error');
        }
    });

    // show the current year in footer copyright:
    var currentYear = new Date().getFullYear();
    $("span#copyright-year").text(currentYear);


    //change color of date input after setting date
    $('#planned-launch').change(function() {
        var date = $(this).val();
        $(".planned-launch-label").css({'color': 'transparent', 'background-color': 'transparent'});
        $(this).css('color', 'white');
        //$("::-webkit-calendar-picker-indicator").css('background-image', `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="-3 0 24 24"><path fill="%23b5b4b4" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`);
        //console.log(date, 'change')
    });

    // $('#planned-launch').blur(function() {
    //     var date = $(this).val();
    //     $(".planned-launch-label").css('color', 'white');
    //     $(this).css('color', 'white');
    //     //console.log(date, 'blur')
    // });


});
