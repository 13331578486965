// /**
//  * Created by piotr on 08.11.2017.
//  */
//
// console.log("custom.js code works")
//
/*Lazy loading for background images*/
document.addEventListener("DOMContentLoaded", function() {
    var lazyloadImages;

    if ("IntersectionObserver" in window) {
        lazyloadImages = document.querySelectorAll(".lazy");
        var imageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    var image = entry.target;
                    image.classList.remove("lazy");
                    imageObserver.unobserve(image);
                }
            });
        });

        lazyloadImages.forEach(function(image) {
            imageObserver.observe(image);
        });
    } else {
        var lazyloadThrottleTimeout;
        lazyloadImages = document.querySelectorAll(".lazy");

        function lazyload () {
            if(lazyloadThrottleTimeout) {
                clearTimeout(lazyloadThrottleTimeout);
            }

            lazyloadThrottleTimeout = setTimeout(function() {
                var scrollTop = window.pageYOffset;
                lazyloadImages.forEach(function(img) {
                    if(img.offsetTop < (window.innerHeight + scrollTop)) {
                        img.src = img.dataset.src;
                        img.classList.remove('lazy');
                    }
                });
                if(lazyloadImages.length == 0) {
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }
            }, 20);
        }

        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
    }
})

let isMenuOpen = false;
let navbar = document.querySelector('.navbar');
let navbarToggler = document.querySelector('.navbar-toggler');
let navbarClose = document.querySelector('.btn-close');
let navbarDropdown = document.querySelector('#navbarNavDropdown');
let navbarDropdownExpanded = navbarDropdown.getAttribute('aria-expanded');
const navLinks = document.querySelectorAll('.dropdown-item, .nav-btn');
let dropdownMenus = document.querySelectorAll('.dropdown-menu');
let dropdownTogglers = document.querySelectorAll('.dropdown-toggle');

// Mobile menu toggle

navbarToggler.addEventListener('click', toggleMenu);
navbarClose.addEventListener('click', closeMobileMenu);

function toggleMenu() {
    isMenuOpen = !isMenuOpen;

    navbarDropdown.classList.toggle('show');

    if (isOpen) {
        document.body.style.overflow = 'hidden';
        navbar.style.paddingRight = '17px';
    } else {
        document.body.style.overflow = '';
        navbar.style.paddingRight = '0';
    }

    if(navbarDropdownExpanded === "true") {
        navbarDropdownExpanded = "false";
    } else {
        navbarDropdownExpanded = "true";
    }

    navbarDropdown.setAttribute("aria-expanded", navbarDropdownExpanded);
}

// Dropdown toggle

function getTogglerId(className, event, fn) {
    let list = document.querySelectorAll(className);
    for (let i = 0, len = list.length; i < len; i++) {
        list[i].addEventListener(event, fn, false);
    }
}

getTogglerId('.dropdown-toggle', 'click', toggleDropdown);

function closeMenus(){
    for (let j = 0; j < dropdownMenus.length; j++) {
        dropdownMenus[j].classList.remove('show');
    }
    for (let k = 0; k < dropdownTogglers.length; k++) {
        dropdownTogglers[k].classList.remove('show');
        dropdownTogglers[k].setAttribute('aria-expanded','false');
    }
}

function closeMobileMenu() {
    document.body.style.overflow = document.body.style.overflow = '';
    navbar.style.paddingRight = navbar.style.paddingRight = '0';
    navbarDropdown.classList.remove('show');
    navbarDropdown.setAttribute("aria-expanded", 'false');
}

function toggleDropdown(e) {
    let isOpen = this.classList.contains('show');

    closeMenus();

    if (!isOpen) {
        e.target.nextElementSibling.classList.add('show');
        this.classList.add('show');
        this.setAttribute('aria-expanded','true');
    }

    e.preventDefault();
}

// Close dropdowns on focusout

navbar.addEventListener('focusout', function() {
    window.onclick = function(event){
        if (document.querySelector('.navbar').contains(event.target)) return;
        closeMenus();
    };
});

/*Hide mobile menu when any menu link clicked*/

navLinks.forEach((link) => {
    link.addEventListener('click', () => {
        closeMenus();
        closeMobileMenu();
    });
})
